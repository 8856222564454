html, body {
    margin: 0;
    padding: 0;
    font-family: "IBM Plex Sans";
}

p {
    margin: 0;
    padding: 0;
}

#root {
    width: 100%;
    height: 100%;
}
